import React from 'react';
import Popup from 'reactjs-popup';
//import 'reactjs-popup/dist/index.css';
import './modal.css';
import ReactPlayer from 'react-player/youtube'

const CustomPopup = ({url, isLink}) => {return (!isLink ? (
  <Popup
    trigger={<a className="btn"> Explore </a>}
    modal
    nested
  >
    {close => (
      <div className="modal">
        <ReactPlayer controls="true" width="100%" height="100%" url={url} />
        <button className="close" onClick={close}>
          &times;
        </button>
        
      </div>
    )}
  </Popup>) : <a href={url} target='_blank' className='btn'> Explore </a> )}
  
  export default CustomPopup