// Skills Icons=
import linkedinIcon from "./images/linkedin.svg"

// Social Icon
import instagramIcon from "./images/instagram.svg"

// Images
import mouad from "./images/mouad.jpg"
import aeImg from "./images/ae.png"
import premiereImg from "./images/premiere.png"
import resolveImg from "./images/resolve.png"
import blenderImg from "./images/blender.png"

// projects
import boschImg from "./images/bosch.jpg"
import freeBudsImg from "./images/FreeBuds4i.png"
import fatnaImg from "./images/fatna.jpg"
import safaaImg from "./images/safaahanaa.jpg"


export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Mouad",
  headerTagline: [
    //Line 1 For Header
    "FILMMAKER",
    //Line 2 For Header
    "",
    //Line 3 For Header
    "",
  ],
  //   Header Paragraph
  headerParagraph:
    "",

  //Contact Email
  contactEmail: "mouad.elmekaoui@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Huawei FreeBuds 4i", //Project Title - Add Your Project Title Here
      para:
        "I edited and color graded this spot for Huawei", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        freeBudsImg,
      //Project URL - Add Your Project Url Here
      url: "https://www.youtube.com/watch?v=Ag-54WRpRG0&ab_channel=MouadElMekaoui",
      isLink: false
    },
    {
      title: "Recipe Videos - Bosch", //Project Title - Add Your Project Title Here
      para:
        "I edited and color graded several food spots for Bosch", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        boschImg,
      //Project URL - Add Your Project Url Here
      url: "https://www.instagram.com/p/COLRorMK4oL/",
      isLink: true
    },
    {
      title: "Fatna (Documentary)", //Project Title - Add Your Project Title Here
      para:
        "I shot, edited and color graded this documentary", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        fatnaImg,
      //Project URL - Add Your Project Url Here
      url: "https://drive.google.com/file/d/1Cg7oWwMYS1d7wQB_2bkdeiHCm0XfrzZH/preview?t=2224",
      isLink: true
    },
    {
      title: "Safaa & Hanaa - Iltizam (Teaser)", //Project Title - Add Your Project Title Here
      para:
        "I edited and did VFX on this teaser for Safaa and Hanaa", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        safaaImg,
      //Project URL - Add Your Project Url Here
      url: "https://www.instagram.com/p/CMP-XGBnqTa/",
      isLink: true
    },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "I'm Mouad El Mekaoui, a camera operator, video editor and colorist based in Rabat, Morocco. I got my license degree from ISMAC Rabat. I work as a freelance editor and colorist for various clients.",
  aboutParaTwo:
    "",
  aboutParaThree:
    "",
  aboutImage:
    mouad,

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: premiereImg,
      para:
        "I can edit your videos dynamical and create an engaging content",
    },
    {
      img: resolveImg,
      para:
        "I can professionally color grade your videos using the most advanced tools inside of Davinci Resolve",
    },
    {
      img: aeImg,
      para:
        "I can animate your graphics or do VFX compositing inside of After Effects",
    },
    {
      img: blenderImg,
      para:
        "I can create 3D scenes and render videos using Blender"
    }
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "",
  promotionPara:
    "",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together",
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/mouad-el-mekaoui-934bb6207/",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/mouad_elmekaoui/",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
